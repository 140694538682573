(function hello() {
  if (!window.console) return;

  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("chrome") > -1 || ua.indexOf("firefox") > -1) {
    let args = [
      "%c 🧠 + ✍ with ❤️ by Zenit Creative",
      "font-size:10px;font-weight: bold;color:#fff; background-color:#162ff0; padding:5px;border-radius:2px;",
    ];

    window.console.log.apply(console, args);
  } else window.console.log("🧠 + ✍ with ❤️ by Zenit Creative");
})();
