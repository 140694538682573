class CookieConsent {
  constructor() {
    let cc = initCookieConsent();

    cc.run({
      autorun: true,
      delay: 0,
      current_lang: "en",
      auto_language: false,
      autoclear_cookies: true,
      cookie_expiration: 365,
      force_consent: false,

      onAccept: function (cookie) {
        console.log("onAccept fired ...");

        if (cc.allowedCategory("analytics")) {
          cc.loadScript(
            "https://www.google-analytics.com/analytics.js",
            function () {
              ga("create", "UA-xxxxxxxx-x", "auto");
              ga("send", "pageview");
              console.log("analytics.js loaded");
            }
          );
        }
      },

      onChange: function (cookie) {
        console.log("onChange fired ...");
      },

      languages: {
        en: {
          consent_modal: {
            title: "",
            description:
              'Este site usa cookies essenciais para garantir seu bom funcionamento e cookies de rastreamento para entender como você interage com ele. Este último será definido somente após consentimento. <button type="button" data-cc="c-settings" class="cc-link">Deixe-me escolher</button>',
            primary_btn: {
              text: "Aceitar",
              role: "accept_all", //'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: "Rejeitar",
              role: "accept_necessary", //'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: "<div>Configurações de cookies</div>",
            save_settings_btn: "Salvar configurações",
            accept_all_btn: "Aceitar tudo",
            close_btn_label: "Fechar",
            cookie_table_headers: [
              { col1: "Name" },
              { col2: "Domain" },
              { col3: "Expiration" },
              { col4: "Description" },
              { col5: "Type" },
            ],
            blocks: [
              {
                title: "Cookies usados",
                description:
                  'Utilizamos cookies para garantir as funcionalidades básicas do site e para melhorar a sua experiência online. Você pode escolher para cada categoria ativar ou desativar sempre que desejar. Para obter mais detalhes sobre cookies e outros dados confidenciais, leia a nossa <a href="#" class="cc-link">política de privacidade</a>.',
              },
              {
                title: "Cookies necessários",
                description:
                  "Esses cookies são essenciais para o bom funcionamento do site. Sem esses cookies, o mesmo não funcionaria corretamente",
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true, //cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: "Cookies de preferências",
                description:
                  "Esses cookies permitem que o site se lembre das escolhas que você fez no passado",
                toggle: {
                  value: "preferences", //there are no default categories => you specify them
                  enabled: false,
                  readonly: false,
                },
              },
            ],
          },
        },
      },
    });
  }
}
export default CookieConsent;
