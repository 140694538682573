import jQuery from "jquery";
import { gsap } from "gsap";
import ImagesLoaded from "@utils/imagesLoaded";

const siteTransitionMask = jQuery(".site-transition__mask");
const siteTransition = jQuery("[data-site-transition]");

class OverlayTransition {
  constructor() {
    this.name = "overlay-transition";

    this._imagesLoaded = false;
    this._imgLoader = null;
    this._onImagesLoaded = this._onImagesLoaded;
  }

  async beforeLeave({ next }) {
    this._imagesLoaded = false;
    if (!next.container) return;
    this._imgLoader = new ImagesLoaded(next.container, this._onImagesLoaded);
  }

  async beforeEnter(data) {
    if (this._imagesLoaded === true || !this._imagesLoaded) return;

    return new Promise((resolve) => {
      if (!this._imgLoader)
        this._imgLoader = new ImagesLoaded(
          data.next.container,
          this._onImagesLoaded
        );

      this._imgLoader.once("always", resolve);
    });
  }

  async leave(data) {
    this.fadeOut(data.current.container);
    await this.delay(1000);
    data.current.container.remove();
  }

  async enter({ next }) {
    this.fadeIn(next.container);
    jQuery("body").addClass("loaded");
  }

  transitionIn({ container }) {
    const tl = gsap.timeline({
      defaults: {
        duration: 1,
        ease: "power2.inOut",
      },
    });
    tl.set(siteTransition, { autoAlpha: 1 })
      .fromTo(siteTransition, { xPercent: -100 }, { xPercent: 0 })
      .fromTo(siteTransitionMask, { xPercent: 80 }, { xPercent: 0 }, 0);
    return tl;
  }

  transitionOut({ container }) {
    const tl = gsap.timeline({
      defaults: {
        duration: 1.1,
        ease: "power2.inOut",
      },
      onStart: async () => {
        await this.initScript();
      },
    });
    tl.to(siteTransition, { xPercent: 100 }).to(
      siteTransitionMask,
      { xPercent: -80 },
      0
    );
    return tl;
  }

  fadeIn = (container) => {
    return gsap.fromTo(
      container,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.5 }
    );
  };

  fadeOut = (container) => {
    return gsap.to(container, { autoAlpha: 0, duration: 0.5 });
  };

  _onImagesLoaded() {
    this._imagesLoaded = true;

    this._imgLoader.destroy();
    this._imgLoader = null;
  }
  delay(n) {
    n = n || 2000;
    return new Promise((resolve) => {
      setTimeout(resolve, n);
      console.log("delay ok");
    });
  }
  initScript() {
    console.log("initScript na overlayTransition");
    console.log("initScript na overlayTransition ending");
  }
}

export default OverlayTransition;
