import $ from "jquery";

import {
  featuredSwiper,
  teamSwiper,
  projectsSwiper,
  scholarshipSwiper,
  studentsSwiper,
  workSwiper,
  bryanExploreSwiper,
} from "../modules/slide/index";

import {
  homeTeamMore,
  homeParallax,
  scholarshipParallax,
  customSelect,
  openPrinciple,
  faqs,
  videoHover,
  rainbowFun,
  videoPlay,
  rainbowCover,
  grayCover,
  filterMenu,
  stickFilterMenu,
  scrollTest
} from "../modules/tricks/index";

import {
  uploadMultipleFiles,
} from "../modules/form/index"

import { makers, students } from "../modules/makers/index";
import { initBookCall } from "../modules/book-a-call/index";

import { projects, projectSelection } from "../modules/projects/index";

import { textSplit } from "@modules/text-split";

export default [
  {
    namespace: "page-templates/page-makers-originals",
    beforeEnter(){
      textSplit();
      projects();
      videoHover();
    }
  },
  {
    namespace: "page-templates/page-the-solution",
    beforeEnter(){
      textSplit();
      openPrinciple();
    }
  },
  {
    namespace: "page-templates/page-news",
    beforeEnter(){
      rainbowCover();
      textSplit();
      rainbowFun();
    }
  },
  {
    namespace: "home",
    beforeEnter() {
      initBookCall(); // TODO: move to the proper page and change cta trigger in js file

      textSplit();
      featuredSwiper();
      teamSwiper();
      homeTeamMore();
      makers();
      projectsSwiper();
      homeParallax();
      videoHover();
      rainbowFun();
      videoPlay();
      rainbowCover();
    },
  },
  {
    namespace: "page-templates/page-principles",
    beforeEnter() {
      textSplit();
      openPrinciple();
      rainbowFun();
    },
  },
  {
    namespace: "page-templates/page-we-make-anything",
    beforeEnter() {
      videoHover();
      textSplit();
      projects();
      rainbowFun();
      grayCover();
      filterMenu();
      stickFilterMenu();
      scrollTest();
    },
    afterEnter() {
      projectSelection();
    }
  },
  {
    namespace: "archive-project",
    beforeEnter() {
      videoHover();
      textSplit();
      projects();
      rainbowFun();
    },
  },
  {
    namespace: "page-templates/page-meet-your-maker",
    beforeEnter() {
      textSplit();
      makers();
      rainbowFun();
      rainbowCover();
    },
  },
  {
    namespace: "page-templates/page-scholarship",
    beforeEnter() {
      textSplit();
      scholarshipParallax();
      scholarshipSwiper();
      studentsSwiper();
      faqs();
      videoHover();
      projects();
      rainbowFun();
      students();
    },
  },
  {
    namespace: "page-templates/page-work-with-us",
    beforeEnter() {
      rainbowFun();
      textSplit();
      workSwiper();

      var outerContent = $("#members");
      var innerContent = $(".content-members");

      outerContent.scrollLeft(
        (innerContent.width() - outerContent.width()) / 2
      );
    },
  },
  {
    namespace: "page-templates/page-contact-us",
    beforeEnter() {
      textSplit();
      rainbowFun();
    },
  },
  {
    namespace: "page-templates/page-mission",
    beforeEnter() {
      textSplit();
      rainbowFun();
    },
  },
  {
    namespace: "page-templates/page-apply-today",
    beforeEnter() {
      textSplit();
      scholarshipParallax();
      faqs();
      customSelect();
      uploadMultipleFiles();
      rainbowFun();
    },
  },
  {
    namespace: "single-project",
    beforeEnter(data) {
      textSplit();
      videoHover();
      projects();
      rainbowFun();
    },
  }
];