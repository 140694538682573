import $ from "jquery";

function makers() {
  $(document).ready(function () {
    $(".linkMember").on("click", function (e) {
      e.preventDefault();
      return false;
    });
    $("#meetYourMaker .linkMember, #homeTeam .bt-plus, .linkMember").on(
      "click",
      function (e) {
        $("#lightBoxMember").addClass("open");
        e.preventDefault();
        //var topscroll = $(window).width() > 1023 ? $(window).scrollTop() : 0
        var makerslug = $(this).attr("data-slug");

        $("#lightBoxMember .photo").html("");
        $("#lightBoxMember .title").text("");
        $("#lightBoxMember .subtitle").text("");
        $("#lightBoxMember .bigText p").text("");
        $("#lightBoxMember .smallText").html("");
        $("#lightBoxMember .linkProjects").html("");
        $("#lightBoxMember .extraBlocks").empty()
        $("#lightBoxMember .cornerTitle").html("MEET YOUR MAKER")

        $.ajax({
          type: "POST",
          url: adminAjax,
          dataType: "json", // add data type
          data: {
            action: "get_ajax_posts",
            slug: makerslug,
          },
          success: function (response) {
            var img = $("<img>");
            var titleMobile =
              '<p class="h2 titleMobile d-lg-none">' + response.title + "</p>";
            img.attr("src", response.photo.replace("localhost:8000", "localhost:3000"));
            img.attr("alt", response.title);
            img.appendTo("#lightBoxMember .photo");
            $("#lightBoxMember .photo").append(titleMobile);

            $("#lightBoxMember .photo").append("<div class=\"thumb-rainbow\"></div>")

            $("#lightBoxMember .title").text(response.title);
            $("#lightBoxMember .subtitle").text(response.occupation);
            $("#lightBoxMember .bigText p").text(response.subtitle);
            $("#lightBoxMember .smallText").html(response.about);
            
/*             var totalProjects = response.projects.length;
            var htmlProjects = "";
            for (var i = 0; i < totalProjects; i++) {
              htmlProjects +=
                '<a href="' +
                response.projects[i].link +
                '"><div class="thumb"><img src="' +
                response.projects[i].thumb +
                '" alt="' +
                response.projects[i].name +
                '"></div><p class="p3">' +
                response.projects[i].name +
                "</p></a>";
            }
            $("#lightBoxMember .linkProjects").html(htmlProjects); */

            //$('#lightBoxMember .member').css('top', topscroll)
            $("#lightBoxMember").addClass("loaded");
            if ($("#lightBoxMember .member").height() > $(window).height()) {
              $("body, #lightBoxMember").addClass("hideScroll");
            }

            window.location.hash = "!/" + makerslug;

            $("body").addClass("freezeBody")
          },
        });
      }
    );
    $("#lightBoxMember .bt-closeMember, #lightBoxMember .bg").on(
      "click",
      function (e) {
        $("body").removeClass("freezeBody")
        e.preventDefault();
        $("#lightBoxMember").removeClass("open");
        setTimeout(function () {
          $("#lightBoxMember").removeClass("loaded");
        }, 500);
        $("body, #lightBoxMember").removeClass("hideScroll");
        //$('#lightBoxMember .member').css('top', '')
        //window.location.hash = '!/'
        var uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
          var clean_uri = uri.substring(0, uri.indexOf("?"));
          window.history.replaceState({}, document.title, clean_uri);
        } else if (uri.indexOf("#") > 0) {
          var clean_uri = uri.substring(0, uri.indexOf("#"));
          window.history.replaceState({}, document.title, clean_uri);
        }
      }
    );
    getUrlHash();
  });

  var anchor;
  function getUrlHash() {
    var urlHash = window.location.href;
    var url = window.location.href;
    if (urlHash.indexOf("#") != "-1") {
      urlHash = urlHash.replace("/", "");
      var hashes = urlHash.slice(urlHash.indexOf("#") + 3).split("/");
      anchor = hashes[0];
    }

    if (anchor != "" && anchor != undefined) {
      var id = "#meetYourMaker .link-" + anchor;
      $(id).trigger("click");
    } else {
      var maker = getUrlParameter("maker");
      if (maker) {
        //var topscroll = $(window).scrollTop()
        //$('#lightBoxMember .member').css('top', topscroll)
        $("#lightBoxMember").addClass("open");
        if ($("#lightBoxMember .member").height() > $(window).height()) {
          $("body, #lightBoxMember").addClass("hideScroll");
        }
      }
    }
  }

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return typeof sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };

  var rtime;
  var timeout = false;
  var delta = 300;
  $(window).on("resize", function () {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      if ($("#lightBoxMember .member").height() > $(window).height()) {
        $("body, #lightBoxMember").addClass("hideScroll");
      }
    }
  }
}

function students() {
  $(document).ready(function () {
    $(".linkMember").on("click", function (e) {
      e.preventDefault();
      return false;
    });
    $("#meetYourMaker .linkMember, #homeTeam .bt-plus, .linkMember").on(
      "click",
      function (e) {
        $("#lightBoxMember").addClass("open");
        e.preventDefault();
        //var topscroll = $(window).width() > 1023 ? $(window).scrollTop() : 0
        var makerslug = $(this).attr("data-slug");

        $("#lightBoxMember .photo").html("");
        $("#lightBoxMember .title").text("");
        $("#lightBoxMember .subtitle").text("");
        $("#lightBoxMember .bigText p").text("");
        $("#lightBoxMember .smallText").html("");
        $("#lightBoxMember .linkProjects").html("");
        $("#lightBoxMember .extraBlocks").empty()
        $("#lightBoxMember .cornerTitle").html("")

        $.ajax({
          type: "POST",
          url: adminAjax,
          dataType: "json", // add data type
          data: {
            action: "get_ajax_students",
            slug: makerslug,
          },
          success: function (response) {
            var img = $("<img>");
            var titleMobile =
              '<p class="h2 titleMobile d-lg-none">' + response.title + "</p>";
            img.attr("src", response.photo.replace("localhost:8000", "localhost:3000"));
            img.attr("alt", response.title);
            img.appendTo("#lightBoxMember .photo");
            $("#lightBoxMember .photo").append(titleMobile);

            $("#lightBoxMember .photo").append("<div class=\"thumb-rainbow\"></div>")

            $("#lightBoxMember .title").text(response.title);
            $("#lightBoxMember .subtitle").text(response.occupation);
            $("#lightBoxMember .bigText p").text(response.subtitle);
            $("#lightBoxMember .smallText").html(response.about);

            var blocks = response.blocks;
            var extraBlocks = $(".extraBlocks")
            extraBlocks.html("")
            console.log(blocks)
            if (blocks){
              console.log(blocks)
              for (var i in blocks){
                if(blocks[i].acf_fc_layout == "text_block"){
                  const textBlock = $("<div>").addClass("textBlock").addClass("smallText")
                  const text = $("<p>").html(blocks[i].text).css("font-weight",400)
                  textBlock.append(text)
                  extraBlocks.append(textBlock)

                }
                if(blocks[i].acf_fc_layout == "image_block"){
                  var img = $("<img>");
                  var titleMobile =
                    '<p class="h2 titleMobile d-lg-none">' + response.title + "</p>";
                  img.attr("src", blocks[i].photo.replace("localhost:8000", "localhost:3000"));
                  img.attr("alt", blocks[i].title);
                  img.appendTo(extraBlocks)
                  console.log(img)
                }
                if(blocks[i].acf_fc_layout == "video_block"){
                  const videoBlock = $("<div>").addClass("videoBlock").css("text-align", "center")
                  videoBlock.html(blocks[i].video)
                  extraBlocks.append(videoBlock)
                }
              }
            }
            $("#lightBoxMember").addClass("loaded");
            if ($("#lightBoxMember .member").height() > $(window).height()) {
              $("body, #lightBoxMember").addClass("hideScroll");
            }

            window.location.hash = "!/" + makerslug;

            $("body").addClass("freezeBody")
          },
        });
      }
    );
    $("#lightBoxMember .bt-closeMember, #lightBoxMember .bg").on(
      "click",
      function (e) {
        $("body").removeClass("freezeBody")
        e.preventDefault();
        $("#lightBoxMember").removeClass("open");
        setTimeout(function () {
          $("#lightBoxMember").removeClass("loaded");
        }, 500);
        $("body, #lightBoxMember").removeClass("hideScroll");
        //$('#lightBoxMember .member').css('top', '')
        //window.location.hash = '!/'
        var uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
          var clean_uri = uri.substring(0, uri.indexOf("?"));
          window.history.replaceState({}, document.title, clean_uri);
        } else if (uri.indexOf("#") > 0) {
          var clean_uri = uri.substring(0, uri.indexOf("#"));
          window.history.replaceState({}, document.title, clean_uri);
        }
      }
    );
    getUrlHash();
  });

  var anchor;
  function getUrlHash() {
    var urlHash = window.location.href;
    var url = window.location.href;
    if (urlHash.indexOf("#") != "-1") {
      urlHash = urlHash.replace("/", "");
      var hashes = urlHash.slice(urlHash.indexOf("#") + 3).split("/");
      anchor = hashes[0];
    }

    if (anchor != "" && anchor != undefined) {
      var id = ".meetYourStudent .link-" + anchor;
      $(id).trigger("click");
    } else {
      var maker = getUrlParameter("students");
      if (maker) {
        //var topscroll = $(window).scrollTop()
        //$('#lightBoxMember .member').css('top', topscroll)
        $("#lightBoxMember").addClass("open");
        if ($("#lightBoxMember .member").height() > $(window).height()) {
          $("body, #lightBoxMember").addClass("hideScroll");
        }
      }
    }
  }

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return typeof sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };

  var rtime;
  var timeout = false;
  var delta = 300;
  $(window).on("resize", function () {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      if ($("#lightBoxMember .member").height() > $(window).height()) {
        $("body, #lightBoxMember").addClass("hideScroll");
      }
    }
  }
}


export { makers, students };
