import { $, $$ } from "@utils/dom";
import jQuery from "jquery";
import { gsap } from "gsap";
import ImagesLoaded from "@utils/imagesLoaded";
import { instance } from "@ui/site-scroll";

const SELECTOR = "[data-site-loader]";

class siteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.name = "loader";
  }

  beforeOnce({ next }) {
    return new Promise((resolve) => {
      this._imgLoader = new ImagesLoaded(next.container, resolve);
    });
  }

  once() {
    return new Promise((resolve) => {
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        jQuery("html").addClass("safari");
      }

      const loaderHome = jQuery("#loaderHome");
      var posHeader = jQuery(".sectionHeader").offset();
      var cssHeader = jQuery(".sectionHeader .titleSite").css([
        "font-size",
        "line-height",
        "letter-spacing",
        "width",
        "height",
      ]);
      var posBrand = jQuery("#siteHeader .navbrand").offset();
      var cssBrand = jQuery("#siteHeader .navbrand").css(["width", "height"]);

      var posTitle = jQuery("#loaderHome .contentTitle").offset();
      var posLogo = jQuery("#loaderHome .logoSite makers-logo").offset();

      var scale =
        jQuery("#loaderHome .contentTitle").width() /
        jQuery(".sectionHeader .titleSite").width();

      if (jQuery("body").hasClass("home")) {
        loaderHome.addClass("show1");
        jQuery("#homeFeatured .sectionHeader").removeClass("view");
        //jQuery("#siteHeader .logoSite").addClass("hide");
        setTimeout(function () {
          loaderHome.removeClass("show0");
          loaderHome.addClass("show2");
          setTimeout(function () {
            loaderHome.addClass("show3");
            setTimeout(function () {
              loaderHome.addClass("show4");
              //instance.destroy();
              //jQuery("*").removeClass("is-inview");
              setTimeout(function () {
                loaderHome.addClass("goPos");
                jQuery("#loaderHome .contentTitle").css({
                  top: posTitle.top,
                  left: posTitle.left,
                });
                jQuery("#loaderHome .logoSite makers-logo").css({
                  top: posLogo.top,
                  left: posLogo.left,
                });
                setTimeout(function () {
                  loaderHome.addClass("goPos2");
                  jQuery("#loaderHome .contentTitle").css({
                    top: posHeader.top,
                    left: posHeader.left,
                  });
                  jQuery("#loaderHome .logoSite makers-logo").css({
                    top: posBrand.top,
                    left: posBrand.left,
                    width: cssBrand["width"],
                    height: cssBrand["height"],
                  });
                  //instance.init();

                  setTimeout(function () {
                    loaderHome.addClass("goPos3");
                    loaderHome.hide();
                    jQuery("#homeFeatured .sectionHeader").addClass("view");
                    jQuery("#siteHeader .logoSite").removeClass("hide");
                    jQuery("body").removeClass("loading");
                    jQuery("body").addClass("loaded");

                    resolve();
                  }, 6);
                }, 3);
              }, 12);
            }, 6);
          }, 6);
        }, 10);
      } else {
        jQuery(".loader").fadeOut("slow");
        jQuery("body").addClass("loaded");
        resolve();
      }
    });
  }
}

export default siteLoader;
