import barba from "@barba/core";
import scripts from "@mill3-packages/barba-scripts";
import domready from "@mikaelkristiansson/domready";

import "@core/hello";
import "@core/getSw";
import "@core/barba";
import "@modules/cookieconsent";
import "@modules/makers-logo";
import { siteMenu, preventReload } from "@modules/tricks";

// import { actions as moduleActions } from "@modules";
import BarbaWebpackChunks from "@core/barba.webpack-chunks";
import MobileViewportUnit from "@core/mobile-vh";
import { mobile } from "@utils/mobile";
import transitions from "@transitions";
import views from "@views";

// Theme SCSS.
import "../scss/app.scss";

barba.use(scripts);

const BarbaWebpackChunksInstance = new BarbaWebpackChunks();
barba.use(BarbaWebpackChunksInstance);

/*
 * Main app
 */
class App {
  constructor() {
    this.init();
  }

  init() {
    if ("scrollRestoration" in history) history.scrollRestoration = "manual";
    // this.siteScroll = new SiteScroll(false);
    preventReload();

    siteMenu();

    // if mobile, create mobile vh fix
    if (mobile) new MobileViewportUnit.init();

    // init barba
    barba.init({
      name:'self',
      debug: process.env.NODE_ENV === "development",
      logLevel: 4,
      sync: false,
      timeout: 5000,
      preventRunning: true,
      prevent: ({ el }) => {
        if (
          /.pdf/.test(el.href.toLowerCase()) ||
          /.jpg/.test(el.href.toLowerCase()) ||
          /.png/.test(el.href.toLowerCase()) ||
          /.gif/.test(el.href.toLowerCase())
        ) {
          return true;
        }

        if (el.classList && el.classList.contains("ais-Pagination-link")) {
          return true;
        }
      },
      transitions: transitions,
      views: views,
    });
  }
}

// console.log(process.env.THEME_NAME);
// console.log(process.env.MY_HOST);
// console.log(process.env.DEV_SERVER_PORT);
// console.log(process.env.ENVIRONMENT_THEME);

domready(() => {
  setTimeout(() => new App(), process.env.NODE_ENV === "development" ? 500 : 0);
});

// Isso permite a aplicação de alterações de módulos JS na visualização HTML.
// Não o remova!
if (module.hot) {
  module.hot.accept();
}
