import $ from "jquery";

function textSplit() {
  $(".js-split").each(function () {
    var text = $(this).text();
    var arrayText = text.split("\n");
    var htmlText = "";
    for (var i = 0; i < arrayText.length; i++) {
      var tempText = arrayText[i];
      var time = i * 20;
      htmlText +=
        '<span class="span-animate"><span data-anim-delay="' +
        time +
        '0">' +
        arrayText[i] +
        "</span></span>";
    }
    $(this).html(htmlText);
  });
  $(".js-splitWord").each(function () {
    var text = $(this).text();
    var arrayText = text.split(" ");
    var htmlText = "";
    for (var i = 0; i < arrayText.length; i++) {
      var tempText = arrayText[i];
      var time = i * 20;
      htmlText +=
        '<span class="span-animateWord"><span data-anim-delay="' +
        time +
        '">' +
        tempText +
        "&thinsp;</span></span>";
    }
    $(this).html(htmlText);
  });
}

export { textSplit };
