import $ from "jquery";
import { instance } from "@ui/site-scroll";
import barba from "@barba/core";

function projects() {
  var projectsLeft = Infinity
  $(".scroll-container").on("locomotivescroll", function(e) {
    try{
      //console.log(instance.previousScrollY + " " + instance.scroll.scroll.instance.limit.y)
      var bottom = instance.scroll.scroll.instance.limit.y - instance.previousScrollY
      //console.log(bottom)
      if(bottom < 2000  && projectsLeft > 0){
        //console.log("LOAD")
        $(".bt-moreProjects").click()
      }
    }
    catch{
      console.log("first locomotivescroll call")
    }
  });

  $(".bt-moreProjects").on("click", function (e) {
    e.preventDefault();
    let projects = 4;
    let countShow = 0;
    let countLeft = $(".postsCount").data("count");
    let projectsTemp = countLeft - projects;
    projectsLeft = projectsTemp < 0 ? 0 : projectsTemp;
    $(".postsCount").data("count", projectsLeft);
    if (projectsLeft >= 0 && $(".containerProjects .hide").length) {
      $(".containerProjects .hide").each(function () {
        if (countShow < projects) {
          $(this).fadeIn("slow");
          $(this).removeClass("hide");
          $(this).addClass("show");
        } else {
          return false;
        }
        countShow++;
      });
    }
    if (projectsLeft == 0) {
      $(this).fadeOut("slow");
    }
    instance.update();
  });
  
  var videoURL = $(".contentVideo iframe").prop("src");
  try{
    videoURL = videoURL.replace("&autoplay=1", "");
  }catch{
    console.log("caught videoURL variable problem")
  }
  
  $(".bt-play").on("click", function (e) {
    e.preventDefault();
    $("#lightboxVideo").addClass("open");
    $("body").addClass("openVideo");
  });

  $("#lightboxVideo .bt-closeVideo, #lightboxVideo .bg").on(
    "click",
    function (e) {
      e.preventDefault();
      $("#lightboxVideo").removeClass("open");
      $("body").removeClass("openVideo");
/*       $(".contentVideo iframe").prop("src", "");
      $(".contentVideo iframe").prop("src", videoURL); */
    }
  );

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  function showMore(){
    let projects = 4;
    let countShow = 0;
    let countLeft = $(".postsCount").data("count");
    let projectsTemp = countLeft - projects;
    let projectsLeft = projectsTemp < 0 ? 0 : projectsTemp;
    $(".postsCount").data("count", projectsLeft);
    if (projectsLeft >= 0 && $(".containerProjects .hide").length) {
      $(".containerProjects .hide").each(function () {
        if (countShow < projects) {
          $(this).fadeIn("slow");
          $(this).removeClass("hide");
          $(this).addClass("show");
        } else {
          return false;
        }
        countShow++;
      });
    }
    if (projectsLeft == 0) {
      $(this).fadeOut("slow");
      return projectsLeft
    }
    instance.update();
    return projectsLeft
  }
}
function filterProjects(selected){
  console.log(selected)
  
  $("#project-grid").find(".project").each(function(e){
    console.log("hide")
    $(this).hide()
  })

  console.log("try show start")

  for (i in selected){
    console.log(selected[i] + "fp")
    $("#project-grid").find(".project").each(function(e){
      if($(this).hasClass(selected[i])){
        $(this).show()
      }
    })
  }
}

function projectSelection(){
  console.log("try project selection")
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  console.log(params)
  console.log(params['option'] + "ps")
  if(params['option']){
    console.log(params['option'] + "call")
    var options = params['option'].split(',')
    console.log(options)
    filterProjects(options)
  }

  $('.menu-cats input').change(function(e) {
    if(!($(e.target).hasClass("all-tag"))){
      $('.all-tag').prop('checked', false);
    }else{
      if($('.all-tag').is(':checked')){
        $('.menu-cats input').each(function(){
          $(this).prop('checked', true);
        })
      }else{
        $('.menu-cats input').each(function(){
          $(this).prop('checked', false);
        })
      }
    }

    var selected = []
    $('.menu-cats input').each(function(){
      if($(this).is(':checked')){
        selected.push($(this).val())
      }
    })
    if (selected.length == 0){
      $('.all-tag').prop('checked', true);
      $('.menu-cats input').each(function(){
        $(this).prop('checked', true);
      })
      $('.menu-cats input').each(function(){
        if($(this).is(':checked')){
          selected.push($(this).val())
        }
      })
    }
    filterProjects(selected)
    history.pushState({pushed:true}, "filtered", "?option="+selected.join(','))
    console.log("after push state ", window.location.href)
    barba.history.add(window.location.href, "barba") // THIS IS A BARBA BUG SOLVE, SO URL QUERIES WORKS WITH BACK BUTTON
    instance.update();
  });
}

export { projects, projectSelection };


/* function projects() {
   var projectsLeft = Infinity
  $(".scroll-container").on("locomotivescroll", function() {
    var perc = instance.previousScrollY / instance.scroll.scroll.instance.limit.y
    if(perc > 0.92  && projectsLeft > 0){
      //projectsLeft = showMore()
    }
  });  
  
  $(".bt-moreProjects").on("click", function (e) {
    e.preventDefault();
    let projects = 4;
    let countShow = 0;
    let countLeft = $(".postsCount").data("count");
    let projectsTemp = countLeft - projects;
    let projectsLeft = projectsTemp < 0 ? 0 : projectsTemp;
    $(".postsCount").data("count", projectsLeft);
    if (projectsLeft >= 0 && $(".containerProjects .hide").length) {
      $(".containerProjects .hide").each(function () {
        if (countShow < projects) {
          $(this).fadeIn("slow");
          $(this).removeClass("hide");
          $(this).addClass("show");
        } else {
          return false;
        }
        countShow++;
      });
    }
    if (projectsLeft == 0) {
      $(this).fadeOut("slow");
    }
    instance.update();
  });

  var videoURL = $(".contentVideo iframe").prop("src");
  videoURL = videoURL.replace("&autoplay=1", "");

  $(".post .bt-play").on("click", function (e) {
    e.preventDefault();
    $("#lightboxVideo").addClass("open");
    $("body").addClass("openVideo");
  });

  $("#lightboxVideo .bt-closeVideo, #lightboxVideo .bg").on(
    "click",
    function (e) {
      e.preventDefault();
      $("#lightboxVideo").removeClass("open");
      $("body").removeClass("openVideo");
      $(".contentVideo iframe").prop("src", "");
      $(".contentVideo iframe").prop("src", videoURL);
    }
  );

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
}

function showMore(){
  let projects = 4;
  let countShow = 0;
  let countLeft = $(".postsCount").data("count");
  let projectsTemp = countLeft - projects;
  let projectsLeft = projectsTemp < 0 ? 0 : projectsTemp;
  $(".postsCount").data("count", projectsLeft);
  if (projectsLeft >= 0 && $(".containerProjects .hide").length) {
    $(".containerProjects .hide").each(function () {
      if (countShow < projects) {
        $(this).fadeIn("slow");
        $(this).removeClass("hide");
        $(this).addClass("show");
      } else {
        return false;
      }
      countShow++;
    });
  }
  if (projectsLeft == 0) {
    $(this).fadeOut("slow");
    return projectsLeft
  }
  instance.update();
  return projectsLeft
}

export { projects }; */
