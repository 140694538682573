class ZntCursor {
  constructor() {
    this.zntCursor = document.createElement("div");
    this.zntPointer = document.createElement("div");
    this.zntFollower = document.createElement("div");
    this.currentX = null;
    this.currentY = null;
    this.pointerCircle = null;
    this.followerCircle = null;
    this.hasInitialized = false;

    const methods = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
    methods
      .filter((method) => method !== "constructor")
      .forEach((method) => {
        this[method] = this[method].bind(this);
      });
  }

  init() {
    this.zntCursor.id = "cursor-wrapper";
    this.zntPointer.id = "cursor-pointer";
    this.zntFollower.id = "cursor-follower";

    this.start();
  }

  start() {
    function isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    if (!isTouchDevice()) {
      if (!this.hasInitialized) {
        this.addCursor();

        this.currentX = window.innerWidth / 2;
        this.currentY = window.innerHeight / 2;

        this.pointerCircle = {
          lastX: this.currentX,
          lastY: this.currentY,
          size: this.zntPointer.getBoundingClientRect(),
        };

        this.followerCircle = {
          lastX: this.currentX,
          lastY: this.currentY,
          size: this.zntFollower.getBoundingClientRect(),
        };
      }

      this.hasInitialized = true;

      this.addEvents();
      this.render();
    }
  }

  update() {
    console.log("update");
  }

  destroy() {
    this.zntCursor.classList.remove("active");
    this.zntCursor.classList.remove("slide");
    this.zntCursor.classList.remove("project");
  }

  addCursor() {
    this.zntCursor.appendChild(this.zntPointer);
    this.zntCursor.appendChild(this.zntFollower);
    document.body.appendChild(this.zntCursor);
  }

  removeCursor() {
    this.zntCursor.remove();
  }

  updatePosition(x, y) {
    this.currentX = x;
    this.currentY = y;
  }

  lerp(from, to, ease) {
    return (1 - ease) * from + ease * to;
  }

  circleMovement(circle, ease, direction) {
    if (direction === "x") {
      return this.lerp(circle.lastX, this.currentX, ease);
    }

    return this.lerp(circle.lastY, this.currentY, ease);
  }

  render() {
    this.pointerCircle.lastX = this.circleMovement(this.pointerCircle, 1, "x");
    this.pointerCircle.lastY = this.circleMovement(this.pointerCircle, 1, "y");
    this.zntPointer.style.transform = `translate(${
      this.pointerCircle.lastX - this.pointerCircle.size.width / 2
    }px, ${this.pointerCircle.lastY - this.pointerCircle.size.height / 2}px)`;

    this.followerCircle.lastX = this.circleMovement(
      this.followerCircle,
      0.6,
      "x"
    );
    this.followerCircle.lastY = this.circleMovement(
      this.followerCircle,
      0.6,
      "y"
    );
    this.zntFollower.style.transform = `translate(${
      this.followerCircle.lastX - this.followerCircle.size.width / 2
    }px, ${this.followerCircle.lastY - this.followerCircle.size.height / 2}px)`;
    requestAnimationFrame(this.render);
  }

  addEvents() {

    window.addEventListener("pointermove", (e) =>
      this.updatePosition(e.clientX, e.clientY)
    );

    document.body.addEventListener("mouseenter", this.showCursor);
    document.body.addEventListener("mouseleave", this.hideCursor);

    document.querySelectorAll("a").forEach((link) => {
      link.addEventListener("mouseover", this.linkEnter);
      link.addEventListener("mouseout", this.linkLeave);
    });

    document.querySelectorAll(".swiper, .linkMember, .linkStudent").forEach((slide) => {
      slide.addEventListener("mouseover", this.slideEnter);
      slide.addEventListener("mouseout", this.slideLeave);
    });

    document.querySelectorAll(".project").forEach((project) => {
      project.addEventListener("mouseover", this.projectEnter);
      project.addEventListener("mouseout", this.projectLeave);
    });

    document.querySelectorAll(".swiper").forEach((project) => {
      project.addEventListener("pointerdown", this.projectClickDown);
      project.addEventListener("pointerup", this.projectClickUp);
    });

    document.querySelectorAll("iframe, .extraBlocks").forEach((iframe) => {
      iframe.addEventListener("mouseover", this.hideCursor);
      iframe.addEventListener("mouseout", this.showCursor);
    });

/*     document.querySelectorAll(".bt-plus").forEach((plus) => {
      plus.addEventListener("mouseover", this.btPlusEnter);
      plus.addEventListener("mouseout", this.btPlusLeave);
    }); */
  }

  removeEvents() {
    window.removeEventListener("pointermove", (e) =>
      this.updatePosition(e.clientX, e.clientY)
    );

    document.body.removeEventListener("mouseenter", this.showCursor);
    document.body.removeEventListener("mouseleave", this.hideCursor);

    document.querySelectorAll("a").forEach((link) => {
      link.removeEventListener("mouseover", this.linkEnter);
      link.removeEventListener("mouseout", this.linkLeave);
    });

    document.querySelectorAll(".swiper, .linkMember, .linkStudent").forEach((slide) => {
      slide.removeEventListener("mouseover", this.slideEnter);
      slide.revemoEventListener("mouseout", this.slideLeave);
    });

    document.querySelectorAll(".project").forEach((project) => {
      project.revemoEventListener("mouseover", this.projectEnter);
      project.removeEventListener("mouseout", this.projectLeave);
    });

    document.querySelectorAll("iframe").forEach((iframe) => {
      iframe.removeEventListener("mouseover", this.hideCursor);
      iframe.removeEventListener("mouseout", this.showCursor);
    });

/*     document.querySelectorAll(".bt-plus").forEach((plus) => {
      plus.removeEventListener("mouseover", this.btPlusEnter);
      plus.removeEventListener("mouseout", this.btPlusLeave);
    }); */
  }

  showCursor() {
    this.zntCursor.style.display = "block";
  }

  hideCursor() {
    this.zntCursor.style.display = "none";
  }

  linkEnter() {
    this.zntCursor.classList.add("active");
  }
  linkLeave() {
    this.zntCursor.classList.remove("active");
  }
  slideEnter() {
    //this.zntCursor.classList.add("slide");
    this.zntCursor.classList.add("blob_big_rainbow_with_content")
  }
  slideLeave() {
    //this.zntCursor.classList.remove("slide");
    this.zntCursor.classList.remove("blob_big_rainbow_with_content")

  }
  projectEnter() {
    this.zntCursor.classList.add("project");
  }
  projectLeave() {
    this.zntCursor.classList.remove("project");
  }

  projectClickDown() {
    this.zntCursor.classList.add("project-click");
  }
  projectClickUp() {
    this.zntCursor.classList.remove("project-click");
  }

/*   btPlusEnter(){
    this.zntCursor.classList.add("bt-plus");
    document.querySelectorAll(".swiper").forEach((slide) => {
      slide.removeEventListener("mouseover", this.slideEnter);
      slide.removeEventListener("mouseout", this.slideLeave);
    });
    this.slideLeave()
  }
  btPlusLeave(){
    this.zntCursor.classList.remove("");
    document.querySelectorAll(".swiper").forEach((slide) => {
      slide.addEventListener("mouseover", this.slideEnter);
      slide.addEventListener("mouseout", this.slideLeave);
    });
    this.slideEnter()
  } */
}

export default ZntCursor;
