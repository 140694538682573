/*import $ from "jquery"
import jQuery from "jquery";
import 'bootstrap-datepicker-webpack' */
//import 'webpack-jquery-ui/datepicker'

import {gsap} from "gsap"

let currentSection = 0;
let currentQuestion = 0;

var formData = {}

// GPT /////////////////////////////////////////////////////////////////////
const chatGptPlaceholderOptions = [
    "I want to build a rocket ship",
    "I want to film a nature documentary",
    "I want to host an event for a premiere",
    "I want to create a billboard ad",
    "I want to help new immigrants",
    "I want to commission a graffiti mural",
]

let _wordIndex = 0;
let _placeholderDisplayText = ''; 
let _gptPlaceholderTimeout = null;
const _speed = 170; // Speed at which each word is printed out
const _delay = 2500; // Delay between showing sentences from "chatGptPlaceholderOptions"

// Calls itself until each word from the sentence is shown
function typeWriter($input, words = [], onComplete) {
    if (_wordIndex < words.length) {
        _placeholderDisplayText += words[_wordIndex] + ' ';
        $input.attr('placeholder', _placeholderDisplayText);
        _wordIndex++;

        _gptPlaceholderTimeout = setTimeout(function(){ typeWriter($input, words, onComplete); }, _speed);
    } else {
        _wordIndex = 0;
        _placeholderDisplayText = '';
        onComplete();
    }
}

// Initialize placeholder text change loop
function printTextLikeChatGPT($input, index = 0) {
    const placeholderFullText = chatGptPlaceholderOptions[index];
    const placeholderWords = placeholderFullText.split(' ');

    let nextIndex = index + 1;
    if(nextIndex >= chatGptPlaceholderOptions.length){
        nextIndex = 0
    }

    typeWriter($input, placeholderWords, function(){
        _gptPlaceholderTimeout = setTimeout(function(){ 
            printTextLikeChatGPT($input, nextIndex) 
        }, _delay);
    });
}

// Starts gpt alike placeholder text change
function startGptPlaceholder($input) {
    clearTimeout(_gptPlaceholderTimeout);
    printTextLikeChatGPT($input);
}
// Stops gpt alike placeholder text change
function stopGptPlaceholder() {
    clearTimeout(_gptPlaceholderTimeout);
}
// END GPT /////////////////////////////////////////////////////////////////////


function inputValidation(input, field, additionalValidation) {
    input.removeClass('error');
    
    const value = input.val();
    if (!value || (additionalValidation && !additionalValidation(value))) {
        input.addClass('error');
        return false;
    }

    formData[field] = value;
    return true;
}

function sliderInputValidation(input, field, values) {
    const value = values ? values[input.val()] : getSliderLabel(input);
    formData[field] = value;
    return true;
}

const sections = [
    {
        title: "General Info",
        progressDom: null,
        questions: [
            {
                dom: "#bookCallQuestionName",
                validate: function() {
                    return inputValidation($(this.dom).find('input'), 'name');
                },
                outro: function(onComplete) {
                    showTransition(`Nice to meet you, ${formData.name}.`, onComplete);
                },
            },
            {
                dom: "#bookCallQuestionCompany",
                validate: function() {
                    const radioValid = inputValidation($(this.dom).find('input[type="radio"]:checked'), 'company');
                    return radioValid || inputValidation($(this.dom).find('input'), 'company');
                },
            },
            {
                dom: "#bookCallQuestionContact",
                validate: function() {
                    const emailValid = inputValidation($(this.dom).find('input').first(), 'email', (email) => {
                        return String(email).toLowerCase().match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                    });

                    // Country is required
                    const countryValid = inputValidation($(this.dom).find("select").first(), "country")  

                    if (!emailValid || !countryValid) {
                        return false;
                      }

                    // Phone is optional
                    const phone = $(this.dom).find('input').last().val();
                    if (phone) {
                        formData.phone = phone; 
                    }

                    // Update confirmation message with email
                    $('#bookCallQuestionComplete').find('.email').html(formData.email);

					return true;
				},
			},
			{
				dom: "#bookCallQuestionMeeting",
				validate: function() {
                    let timePicked = $(this.dom).find('input[type="radio"]:checked').val();
                    if (formData.date && timePicked){
                        formData.time = timePicked;
                    }else{
                        $("#bookCallQuestionMeeting").find(".calendarError").addClass("show")
                        return false
                    }
                    $("#bookCallQuestionMeeting").find(".calendarError").removeClass("show")
                    return true
				},
                outro: function(onComplete) {
                    if (formData.date && formData.time) {
                        showCheckmark(`Great, ${formData.date} at ${formData.time} it is!`, onComplete)
                    } else {
                        onComplete();
                    }
                },
			},
		],
	},
    {
        title: "Vision",
        progressDom: null,
        questions: [
            {
                intro: function(onComplete) {
                    const $input = $(this.dom).find('input');
                    showTransition(`Now, let's dive into your project.`, function(){
                        // Delay placeholder change init to make sure it visually starts from blank input
                        setTimeout(function(){ startGptPlaceholder($input); }, 500);
                        onComplete();
                    });
                },
                dom: "#bookCallQuestionWhat",
                validate: function() {
                    formData.what = $(this.dom).find('input').val() || '';
                    return true
                },
                outro: function(onComplete) {
                    stopGptPlaceholder();
                    onComplete();
                },
            },
            {
                dom: "#bookCallQuestionGoals",
                validate: function() {
                    let value = $(this.dom).find('input[type="radio"]:checked').val();
                    if (!value) {
                        value = $(this.dom).find('input[type="text"]').val() || '';
                    }
                    formData.goals = value
                    return true; 
                },
                outro: function(onComplete) {
                    showTransition('That sounds amazing!', onComplete);
                },
            },
        ],
    },
	{
		title: "Project",
		progressDom: null,
		questions: [
			{
				dom: "#bookCallQuestionProjectProgress",
				values: [
					'Ideation',
					'Conception',
					'Development',
					'Pre-production',
					'Production',
				],
				validate: function() {
					return sliderInputValidation($(this.dom).find('input'), 'progress', this.values);
				},
			},
			{
				dom: "#bookCallQuestionWhatHelp",
				validate: function() {
                    let value = $(this.dom).find('input[type="radio"]:checked').val();
                    if (!value) {
                        value = $(this.dom).find('input[type="text"]').val();
                    }
                    formData.help = value;
                    return true;
				},
			},
			{
				dom: "#bookCallQuestionBudget",
				validate: function() {
					return sliderInputValidation($(this.dom).find('input')[0], 'budget', null);
				},
			},
		],
	},
	{
		title: "Confirm",
		progressDom: null,
		questions: [
			{
				intro: function(onComplete) {
					showTransition('Amazing, just one more thing...', onComplete);
				},
				dom: "#bookCallQuestionGetStarted",
				validate: function() {
                    const isValidated = $(this.dom).find('input').is(':checked');
					if (isValidated) {
                        console.log(formData)
						$.ajax({
							url: "https://email.makers.to/book-a-call/makers",
							type: 'post',
							data: formData,
							success: function (data) {
								console.log("success")
							}
						});
					}else{
                        $("#bookCallQuestionGetStarted").find(".labelText").addClass("error")
                    }
					return isValidated;
				},
			},
			{
				dom: "#bookCallQuestionComplete",
				validate: function() {
					return true;
				}
			},
		],
	},
]

function showTransition(message, onComplete) {
	$('#bookCall_Transition').find('h3').html(message);

	$('#bookCall_Transition').show();
	gsap.fromTo("#bookCall_Transition h3", {opacity: 0}, {opacity: 1, duration: 0.4, onComplete: ()=>{
		gsap.fromTo("#bookCall_Transition .bar", {width: "0%"}, {width: "100%", duration: 0.8, onComplete: ()=>{
			gsap.fromTo("#bookCall_Transition .bar", {width: "100%", marginLeft: "0%"}, {width: "0%", marginLeft: "100%", duration: 0.8, onComplete: ()=>{
				gsap.fromTo("#bookCall_Transition h3", {opacity: 1}, {opacity: 0, duration: 0.4, onComplete: ()=>{
					$('#bookCall_Transition').hide();
					$('#bookCall_Questions').find('.bookCallFormFooter').show();
					$("#bookCall_Transition .bar").css("margin-left", "0%")
					onComplete();
				}});
			}});
		}})
	}})
	$('#bookCall_Questions').find('.bookCallFormFooter').hide();
}

function showCheckmark(message, onComplete){
    $('#coolCheckMark_Transition').find('h3').html(message);
	$('#coolCheckMark_Transition').show();
    gsap.fromTo("#coolCheckMark_Transition h3", {opacity: 0}, {opacity: 1, duration: 0.8, onComplete: ()=>{
        gsap.fromTo("#coolCheckMark", {clipPath: "circle(0% at 26px 46px)"}, {clipPath: "circle(100% at 26px 46px)", duration: 1, onComplete: ()=>{
            gsap.fromTo("#coolCheckMark_Transition h3", {opacity: 1}, {opacity: 0, duration: 0.8, onComplete: ()=>{
                $('#coolCheckMark_Transition').hide();
                $('#bookCall_Questions').find('.bookCallFormFooter').show();
                onComplete();
            }});
        }})
	}})
    $('#coolCheckMark_Transition').find('.bookCallFormFooter').hide();
}

function resetForm() {
    formData = {}

    $("#bookCall_Questions").hide();
    $("#bookCall_Transition").hide();
    $("#coolCheckMark_Transition").hide();
    
    // Reset questions
    $('#bookCall_Questions').find('input[type="text"]').val('');
    $('#bookCall_Questions').find('input[type="email"]').val('');
    $('#bookCall_Questions').find('input[type="radio"]:checked').prop('checked', false);
    $('#bookCall_Questions').find('input[type="checkbox"]:checked').prop('checked', false);
    $('#bookCall_Questions').find('input[type="range"]').val(0);
    $('#bookCall_Questions').find('.sliderFilled').width(0);
    $('#datepicker').datepicker('update', '');
    $('#bookCall_Questions').find('.bookCallFormStep').hide();
    $(sections[0].questions[0].dom).show();
    
    // Reset progress
    $('#bookCall_Questions').find('.progressContainer').removeClass(['active', 'complete']);
    $('#bookCall_Questions').find('.progress span').width('0%');
    sections[0].progressDom.addClass('active');

    // Reset budget slider
    $("#bookCallQuestionBudget").find('p').html("$0");
    
    currentSection = 0;
    currentQuestion = 0;
}

function onNextClick(e) {
	if(e){
		e.preventDefault();
	}

    const question = sections[currentSection].questions[currentQuestion];
    
    const valid = question.validate();
    if (!valid) {
        return;
    }

    $('#bookCall_Questions').find('.bookCallFormStep').hide();

    if (question.outro) {
        question.outro(nextQuestion);
    } else {
        nextQuestion();
    }
}

function onSkipClick(e) {
	if(e){
		e.preventDefault();
	}
    $('#bookCall_Questions').find('.bookCallFormStep').hide();
    nextQuestion();
}

function onBackClick(e) {
	if(e){
		e.preventDefault();
	}    
    $('#bookCall_Questions').find('.bookCallFormStep').hide();
    previousQuestion();
}

function getSliderLabel(input) {
    let value = '$'+parseInt(input.value).toLocaleString();

    if (input.value == input.max) {
        value += " or more";
    }

    return value;
}

function onSliderChange(e) {
    e.preventDefault();

    const percentage = Math.round((e.target.value - e.target.min) / (e.target.max - e.target.min) * 100);
    $(e.target).siblings('.sliderFilled').width(percentage + '%');

    const question = sections[currentSection].questions[currentQuestion];
    const selectedDom = $(question.dom).find('.selected');

    if (selectedDom) {
        const value = getSliderLabel(e.target);
        selectedDom.html(value);
    }
}

function onRadioOtherFocus(e) {
    $(e.target).parent().find('input[type="radio"]').prop('checked', false);
}

function nextQuestion() {
    gtag('event', 'intake-tool-progress', {
        'section_index': currentSection,
        'section_name': sections[currentSection].title,
        'question_index': currentQuestion,
        'question_name': sections[currentSection].questions[currentQuestion].dom.substring(18),
    });

    currentQuestion++;
    setTopBarProgress();
    
    // Check if at end of section
    if (currentQuestion >= sections[currentSection].questions.length) {
        // Update progress
        sections[currentSection].progressDom.removeClass('active').addClass('complete');
        sections[currentSection].progressDom.find('.progress span').width('100%');
        
        // Update current section/question
        currentSection++;
        currentQuestion = 0;
        
        // No more sections
        if (currentSection >= sections.length) {
            return closeBookCall();
        }
        
        sections[currentSection].progressDom.addClass('active');
    }
    
    const progress = Math.round(currentQuestion / sections[currentSection].questions.length * 100);
    sections[currentSection].progressDom.find('.progress span').width(progress + '%');
    
    if (sections[currentSection].questions[currentQuestion].intro) {
        sections[currentSection].questions[currentQuestion].intro(showQuestion);
    } else {
        showQuestion();
    }   
}

function previousQuestion(){
    currentQuestion--;
    if (currentQuestion < 0) {        
        currentSection--;
        currentQuestion = sections[currentSection].questions.length - 1;
    }
    showQuestion();
}

function showQuestion() {
    $(sections[currentSection].questions[currentQuestion].dom).show();
}

function openBookCall(e){
    e.preventDefault();
    
    resetForm();

    $("#bookCall_Questions").show();

    $("#bookCall").addClass("open");
    $("body").addClass("freezeBody");

    gtag('event', 'intake-tool-progress', {
        'section_index': -1,
        'section_name': 'intro',
        'question_index': 0,
        'question_name': 'start-home',
    });
}

function closeBookCall(e){
    if (e) {
        e.preventDefault();
    }
    
    $("#bookCall").removeClass("open");
    $("body").removeClass("freezeBody");
}

function initProgressBar() {
    const footer = $('#bookCall_Questions').find('.bookCallFormFooter');
    sections.forEach(function(section) {
        const dom = $(`<div class="progressContainer"><div class="progress"><span></span></div><p class="title">${section.title}</p></div>`)
        footer.append(dom);
        section.progressDom = dom;
    }); 
}

function setTopBarProgress(){
    let total_num_questions = sections.reduce((total, section)=>{return total + section.questions.length}, 0)
    let current_question_num = sections.slice(0, currentSection).reduce((total, section)=>{return total + section.questions.length}, 0) + currentQuestion
    let perc_progress = current_question_num / total_num_questions * 100
    $(".barFilled").width(perc_progress+"%")
}

function initBookCall(){
    $(document).ready(function () {
        initProgressBar();
        
        // Event listeners
        $('#bookCall_Questions').find('.bookCallFormStep').on('click', '.next', onNextClick);
        $('#bookCall_Questions').find('.bookCallFormStep').on('click', '.skip', onSkipClick);
        $('#bookCall_Questions').find('.bookCallFormStep').on('click', '.bt-view-reverse', onBackClick);
        $('#bookCall_Questions').find('.bookCallFormStep').on('input', 'input[type=range]', onSliderChange);
        $('#bookCall_Questions').find('.bookCallFormStep').on('focus', 'input.radioOther', onRadioOtherFocus);

        $("#closeBookCall").on("click", closeBookCall);
        $(".open-book-call-button").on("click", openBookCall);

        $("#datepicker").datepicker({
            startDate: new Date(),
            format: "dd/mm/yyyy",
            maxViewMode: "days",
            daysOfWeekDisabled: [0,6]
        }).on("changeDate", function(e){
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            formData["date"] = `${monthNames[e.date.getMonth()]} ${e.date.getDate()}`
        })
    });
}

export { initBookCall };
