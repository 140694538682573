import $ from "jquery";

function uploadMultipleFiles(){
    var collection = {} // the files we added many times goes to the same object
    function getFiles(){
        var next_file_id = 0
        for (var j in collection){
            if(next_file_id < j.split("-")[1]){
                next_file_id = j.split("-")[1]
            }
        }
        var fileNames = '' //the files we added a single time
        var files = _('myfile').files
        for (var i = 0; i < files.length; i ++){
            fileNames += files[i].name + " | "
            var fileID = "file-" + parseInt(parseInt(next_file_id) + i + 1)
            collection[fileID] = files[i]
        }
        console.log("added: " + fileNames)
        console.log(collection)
        _('myfile').value = ''
        updateManager()
    }

    function updateManager(){
        $("#files_display").empty()
        for (var i in collection){
            console.log(i)
            var fileBullet = $("<div></div>").addClass("filebullet").attr("id", i)
            var fileDisplayText = $("<p></p>").addClass("fileDisplayText").html(collection[i].name)
            var fileDisplayRemove = $("<p></p>").addClass("fileDisplayRemove").css('color', 'red').html("&#10006")
            $(fileDisplayRemove).on("click", function(e){
                removeFile(e.target.parentElement.id)
            })
            fileDisplayRemove.appendTo(fileBullet)
            fileDisplayText.appendTo(fileBullet)
            fileBullet.appendTo($("#files_display"))
        }
        updateButtonText()
    }

    function removeFile(fileID){
        delete collection[fileID]
        updateManager()
    }

    function _(id){
        return document.getElementById(id)
    }

    function updateButtonText(){
        if (Object.keys(collection).length <= 0){
            _('uploadbutton').innerText = "Upload Files"
        }else{
            _('uploadbutton').innerText = "Upload More"
        }
    }

    function clickUpload(){
        _('myfile').click()
    }

    function UploadFile(){
    var name = document.getElementById("name").value
    var email = document.getElementById("email").value
    var notes = document.getElementById("notes").value
    var school = document.getElementById("school").value
    var link = document.getElementById("link").value
    var checkbox = document.getElementById("checkbox-1").checked ? "yes" : "no"
    var checkbox2 = document.getElementById("checkbox-2").checked ? "yes" : "no"
    //var files = document.getElementById("file").files
    var files = []
    for (var i in collection){
        files.push(collection[i])
    }

    var formdata = new FormData();

    formdata.append("name", name)
    formdata.append("email", email)
    formdata.append("notes", notes)
    formdata.append("school", school)
    formdata.append("link", link)
    formdata.append("checkbox-1", checkbox)
    formdata.append("checkbox-2", checkbox2)

    var total_size = 0;
    for(var i in files){
        formdata.append("file[]", files[i])
        if(files[i].size){
        total_size += files[i].size
        }
        var size_mb = total_size/1000000
        size_mb = size_mb.toFixed(2)
        if(size_mb > 95){
            $("#message").html("Hey! Looks like you need to show us more than 100MB of files. No problem! Please upload your large files from this button, and then come back to finish your application")
            $("#to-google-form").css("display", "");
            $('#submit-button').removeAttr("disabled").val("SUBMIT")
            $("#spinner").css("display", "none")
            return
        }
        $("#message").html("Your file size is fine! Please wait ...")
    }
    var url_origin = window.location.origin
    var path = ""
    if(url_origin.indexOf("localhost")!=-1){
        path = "/wp-content/themes/makers_dev/form/submit.php"
    }else{
        path = "/website/wp-content/themes/makers_dev/form/submit.php"
    }
    $.ajax({
        url: url_origin+path,
        type: 'post',
        data: formdata,
        processData: false,
        contentType: false,
        success: function (data) {
            $("#message").html(data)
           // $("#message").html("Almost done...")
            console.log(data)
            //var e = document.getElementById('successRedirectForm'); 
            //e.submit();
        },
        beforeSend: function (data){
            //window.location = "https://makers.to/thanks"
        },
        xhr: function(){
        var xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", function(evt) {
            if (evt.lengthComputable) {
            var percentComplete = (evt.loaded / evt.total) * 100;
            $("#progress").val(percentComplete);
            console.log(percentComplete)
            }
        }, false);
        return xhr; 
        }
    });
    }
    $(".inputfile").on("change", function(){
        getFiles()
    })
    $("#uploadbutton").on("click", function(){
        clickUpload()
    })

    $("#myform").on('submit', function(e){
        e.preventDefault();
        $("#spinner").css("display", "")
        $("#to-google-form").css("display", "none");
        $('#submit-button').attr('disabled', 'disabled').val("Please wait ...")
        console.log("disable send")
        UploadFile();
    })


    //Everything about FileReader for chunk upload
    var reader = {}
    var file = {}
    var slice_size = 1 * 1024 * 1024
    function start_upload(e){
        e.preventDefault();
        console.log("start upload")
        reader = new FileReader();
        file = collection[Object.keys(collection)[0]]
        upload_file(0);
    }
    //$("#myform").on('submit', start_upload)
    function upload_file(start){
        var next_slice = start + slice_size + 1;
        var blob = file.slice(start, next_slice)
        reader.onloadend = function(e){
            if(e.target.readyState !== FileReader.DONE){
                return
            }
            $.ajax( {
                url: "http://localhost:3000/wp-content/themes/makers_dev/form/submit.php",
                type: 'POST',
                dataType: 'json',
                cache: false,
                data: {
                    file_data: e.target.result,
                    file: file.name,
                    file_type: file.type,
                },
                success: function( data ) {
                    var size_done = start + slice_size;
                    var percent_done = Math.floor( ( size_done / file.size ) * 100 );
    
                    if ( next_slice < file.size ) {
                        // Update upload progress
                        $( '#message' ).html( `Uploading File -  ${percent_done}%` );
    
                        // More to upload, call function recursively
                        upload_file( next_slice );
                    } else {
                        // Update upload progress
                        $( '#message' ).html( 'Upload Complete!' );
                    }
                }
            })
        }
        reader.readAsDataURL(blob);
    }
}

export {uploadMultipleFiles};