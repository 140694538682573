import $ from "jquery";
import { gsap } from "gsap";
import { instance } from "@ui/site-scroll";

function scrollTest(){
/*   $(window).on("load",function() {
    console.log("LOAD")
    window.scrollTo(0, 1000);
 }); */
}

function preventReload() {
  $(document).on("click", "a[href]", function (e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault();
      e.stopPropagation();
    }
  });
}

function siteMenu() {
  let flag = true;

  $(".btMenu").on("click", function (e) {
    e.preventDefault();
    $("body").toggleClass("openMenu");
    $("body").toggleClass("freezeBody");
    $("body").removeClass("openFilterMenu");
    if (flag) {
      setTimeout(function () {
        $("#loaderHome").fadeOut("fast");
        $("#homeFeatured .sectionHeader").addClass("view");
      }, 500);
      flag = false;
    }
  });

  $("#mainMenuContainer").on("click", function (e) {
    $("body").removeClass("openMenu");
    $("body").removeClass("openFilterMenu");
    $("body").removeClass("freezeBody");
  })

  $("#menuPrincipal a").on("click", function (e) {
    $("body").removeClass("openMenu");
    $(".logoSite").removeClass("logo-home");

    //BRYAN: UNSCALABLE CODE, NEED TO REMOVE OR FIX vvv
    if(e.target.textContent.indexOf("home")>-1||e.target.textContent.indexOf("Home")>-1){
      $(".logoSite").addClass("logo-home");
      $(".logoWordmark")[0].style.fill = "white"
    }else{
      $(".logoSite").removeClass("logo-home");
      $(".logoWordmark")[0].style.fill = "transparent"
    } 
    //BRYAN: UNSCALABLE CODE, NEED TO REMOVE OR FIX ^^^ 

  });

}

function filterMenu() {
  $(".filter-button").on("click", function (e) {
    $("body").addClass("openFilterMenu");
    $("body").removeClass("openMenu");
    $("body").addClass("freezeBody");
  });

  $(".filter-close-button").on("click", function (e) {
    $("body").removeClass("openFilterMenu");
    $("body").removeClass("freezeBody");
  });
}

function stickFilterMenu(){
  window.onscroll = function() {detect()};
  var header = document.getElementById("filter-button-container");
  var sheader = document.getElementById("siteHeader");

  var sticky = header.getBoundingClientRect().top;

  function detect() {
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
      header.style.paddingTop = sheader.offsetHeight
    } else {
      header.classList.remove("sticky");
      header.style.paddingTop = "0px"
    }
  }
}

function homeTeamMore() {
  function isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  if (isTouchDevice()) {
    $("#homeTeam").addClass("touch");
    $("#homeTeam .item").on("click", function (e) {
      if ($(this).hasClass("selected")) {
        $(this).removeClass("selected");
      } else {
        $("#homeTeam .item").removeClass("selected");
        $(this).addClass("selected");
      }
    });
  }
}

function homeParallax() {
  function parallax() {
    if (window.innerWidth >= 1024) {
      $(document).mousemove(function (e) {
        if (document.querySelector("#homeProjects .bgCircle")) {
          gsap.to("#homeProjects .bgCircle", {
            duration: 0.2,
            x: -((e.clientX - window.innerWidth / 2 - 1000) / 20) - 50,
            y: -((e.clientY - window.innerHeight / 2 - 1000) / 20),
          });
        }

        if (document.querySelector("#homeAbout .bt-more")) {
          gsap.to("#homeAbout .bt-more", {
            duration: 0.2,
            x: -((e.clientX - window.innerWidth / 2 - 1000) / 20),
            y: -((e.clientY - window.innerHeight / 2 - 1000) / 20),
          });
        }

        if (
          document.querySelector(
            "#homeProjects .swiper-slide-active .contentThumb"
          )
        ) {
          gsap.to("#homeProjects .swiper-slide-active .contentThumb", {
            duration: 0.2,
            x: -((e.clientX - window.innerWidth / 2 - 1000) / 20) - 50,
            y: -((e.clientY - window.innerHeight / 2 - 1000) / 20),
          });
        }
      });
    }
  }

  window.onload = window.onresize = parallax;
}

function scholarshipParallax() {
  $(document).mousemove(function (e) {
    if (document.querySelector("#scene")) {
      gsap.to(".item-1", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 10),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 10),
      });

      gsap.to(".item-2", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 20),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 20),
      });

      gsap.to(".item-3", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 30),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 30),
      });

      gsap.to(".item-4", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 10),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 10),
      });

      gsap.to(".item-5", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 20),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 20),
      });

      gsap.to(".item-6", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 30),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 30),
      });

      gsap.to(".item-7", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 10),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 10),
      });

      gsap.to(".item-8", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 20),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 20),
      });

      gsap.to(".item-9", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 30),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 30),
      });

      gsap.to(".item-10", {
        duration: 0.2,
        x: -((e.clientX - window.innerWidth / 2 - 1000) / 30),
        y: -((e.clientY - window.innerHeight / 2 - 1000) / 30),
      });
    }
  });
}

function openPrinciple() {

  $("#principles .principle").on("click", function (e) {
    e.preventDefault();
    if ($(window).width() < 1024) {
      if ($(this).hasClass("selected")) {
        $(this).removeClass("selected");
      } else {
        $("#principles .principle").removeClass("selected");
        $(this).addClass("selected");
      }
    } else {
      $("#principles .principle").removeClass("selected");
    }
  });

  $("#upsidesContent .upside").on("click", function (e) {
    e.preventDefault();
    if ($(window).width() < 1024) {
      if ($(this).hasClass("selected")) {
        $(this).removeClass("selected");
      } else {
        $("#upsidesContent .upside").removeClass("selected");
        $(this).addClass("selected");
      }
    } else {
      $("#upsidesContent .upside").removeClass("selected");
    }
  });
}

function faqs() {
  $(document).ready(function () {
    var acc = document.getElementsByClassName("accordion");
    var contentfaq = document.getElementsByClassName("content-faq");
    var i;
    var j;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        $(".faq").removeClass("open");

        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          for (j = 0; j < contentfaq.length; j++) {
            contentfaq[j].style.maxHeight = null;
          }
          //panel.style.maxHeight = null;

          setTimeout(function () {
            instance.update();
          }, 500);
        } else {
          for (j = 0; j < contentfaq.length; j++) {
            contentfaq[j].style.maxHeight = null;
          }
          $(this).parent().addClass("open");
          panel.style.maxHeight = panel.scrollHeight + "px";

          instance.update();

          setTimeout(function () {
            instance.update();
          }, 500);
        }
      });
    }
  });

  var rtime;
  var timeout = false;
  var delta = 300;
  $(window).on("resize", function () {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      if ($(".faq.open").length) {
        var faq = $(".faq.open");
        var panel = $(".faq.open .content-faq").get(0);
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }
}

function customSelect() {
  var x, i, j, l, ll, selElmnt, a, b, c;
  /* Look for any elements with the class "custom-select": */
  x = document.getElementsByClassName("custom-select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < ll; j++) {
      /* For each option in the original select element,
    create a new DIV that will act as an option item: */
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function (e) {
        /* When an item is clicked, update the original select box,
        and the selected item: */
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
      /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }

  function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
  except the current select box: */
    var x,
      y,
      i,
      xl,
      yl,
      arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }

  /* If the user clicks anywhere outside the select box,
then close all select boxes: */
  document.addEventListener("click", closeAllSelect);
}

function videoHover() {
  if (document.querySelector(".thumb video")) {
    // Getting video element using jQuery
    var video = $(".thumb video");

    // Check if video is ready to play
    $(video)
      .on("mouseenter", function () {
        $(this).get(0).play();
      })
      .on("mouseleave", function () {
        $(this).get(0).pause();
      });
  }
}

function rainbowFun(){
  var is_home = window.location.pathname == "/" && (window.location.href == "https://makers.to/" || window.location.href == "http://makers.to/")
  var is_lab = window.location.pathname == "/lab/" && (window.location.href == "https://makers.to/lab/" || window.location.href == "http://makers.to/lab/")
  var is_local = window.location.pathname == "/" && window.location.href.indexOf("localhost")!=-1
  var page_slug_list = window.location.pathname.split("/")
  var last_slug = page_slug_list[page_slug_list.length-2]
  var page_name = last_slug.split("-").join(" ").toUpperCase()

  console.log(is_home || is_lab || is_local)

  if (is_home || is_lab || is_local){
    $(".logoSite").addClass("logo-home");
    $("#bread-crumb p").html("> " + "HOME")
  }else{
    $(".logoSite").removeClass("logo-home");
    $(".logoWordmark")[0].style.fill = "transparent"
    $("#bread-crumb p").html("> " + page_name)
  }

  const h = [0, 40, 300, 60]
  var c = [h[0]%360, h[1]%360, h[2]%360, h[3]%360]
/*   document.getElementById("gradient").style.setProperty(
      "background", 
      "linear-gradient(to top left, hsl(0, 0%, 100%), hsla(2, 100%, 79%, 0), hsl("+c[0]+", 100%, 50%)), linear-gradient(to top right, hsl("+c[1]+", 100%, 50%), rgba(255, 153, 150, 0), hsl("+c[2]+", 100%, 50%)) hsl("+c[3]+", 100%, 79%)"
  ) 
  document.getElementById("gradient").addEventListener("mousemove", function(e){  
    const speed = 0.1
    var x_v = e.movementX * speed
    var y_v = e.movementY * speed
    h[0] += x_v; h[1] += x_v; h[2] += x_v; h[3] += x_v; 
    c = [h[0]%360, h[1]%360, h[2]%360, h[3]%360]
    document.getElementById("gradient").style.setProperty(
        "background", 
        "linear-gradient(to top left, hsl(0, 0%, 100%), hsla(2, 100%, 79%, 0), hsl("+c[0]+", 100%, 50%)), linear-gradient(to top right, hsl("+c[1]+", 100%, 50%), rgba(255, 153, 150, 0), hsl("+c[2]+", 100%, 50%)) hsl("+c[3]+", 100%, 79%)"
    )
  }) */

  /* document.getElementById("mainMenu").addEventListener("mouseenter", function(){
    document.getElementById("cursor-wrapper").classList.add("blob_big_rainbow")
  })
  document.getElementById("mainMenu").addEventListener("mouseleave", function(){
    document.getElementById("cursor-wrapper").classList.remove("blob_big_rainbow")
  }) */
}

function videoPlay(){
  var vid = document.getElementById("home-bleed");
  vid.volume = 0.1;
  vid.addEventListener('loadedmetadata', function() {
    this.currentTime = 10;
  }, false);
  vid.play();

  var restart = document.getElementById("bt-restart");
  var mute_toggle = document.getElementById("bt-mute");
  var style_toggle = document.getElementById("bt-style");
  mute_toggle.addEventListener('pointerup', function(){
    vid.muted = !vid.muted;
  })

  restart.addEventListener('pointerup', function(){
    vid.muted = false;
    vid.currentTime = 10;
  })
}

function grayCover(){
  $(".contentThumb").on("pointerenter", function(e){
    gsap.fromTo(
      $(this).find(".thumb-grey"), 
      {duration:0.6, "-webkit-mask-image":"radial-gradient(ellipse at 50% 50%, hsla(0, 0%, 0%, 0), rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.7) 200%)"},
      {duration:0.6, "-webkit-mask-image":"radial-gradient(ellipse at 50% 50%, hsla(0, 0%, 0%, 0), rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 200%)"}
    ) 
    gsap.fromTo(
      $(this).find(".texts"), 
      {duration:0.6, "opacity":1},
      {duration:0.6, "opacity":0}
    ) 
  })

  $(".contentThumb").on("pointerleave", function(e){
    gsap.fromTo(
      $(this).find(".thumb-grey"), 
      {duration:0.6, "-webkit-mask-image":"radial-gradient(ellipse at 50% 50%, hsla(0, 0%, 0%, 0), rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 200%)"},
      {duration:0.6, "-webkit-mask-image":"radial-gradient(ellipse at 50% 50%, hsla(0, 0%, 0%, 0), rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.7) 200%)"}
    ) 
    gsap.fromTo(
      $(this).find(".texts"), 
      {duration:0.6, "opacity":0},
      {duration:0.6, "opacity":1}
    )  
  })
}

function rainbowCover(){
  $(".thumb, .photo").on("pointerover", function(e){
    gsap.fromTo(
      $(this).find(".thumb-rainbow"), 
      {duration:0.5, background:"radial-gradient(circle at 50% 0, hsla(0, 100%, 50%, 0.2), rgba(255,0,0,0) 0%), radial-gradient(circle at 6.7% 75%, hsla(240, 100%, 50%, 0.2), rgba(0,0,255,0) 0%), radial-gradient(circle at 93.3% 75%, hsla(120, 100%, 50%, 0.2), rgba(0,255,0,0) 0%)"},
      {duration:0.5, background:"radial-gradient(circle at 50% 0, hsla(0, 100%, 50%, 0.2), rgba(255,0,0,0) 70.71%), radial-gradient(circle at 6.7% 75%, hsla(240, 100%, 50%, 0.2), rgba(0,0,255,0) 70.71%), radial-gradient(circle at 93.3% 75%, hsla(120, 100%, 50%, 0.2), rgba(0,255,0,0) 70.71%)"}
    )
  })
  $(".thumb, .photo").on("pointerleave", function(e){
    gsap.fromTo(
      $(this).find(".thumb-rainbow"), 
      {duration:0.5, background:"radial-gradient(circle at 50% 0, hsla(0, 100%, 50%, 0.2), rgba(255,0,0,0) 70.71%), radial-gradient(circle at 6.7% 75%, hsla(240, 100%, 50%, 0.2), rgba(0,0,255,0) 70.71%), radial-gradient(circle at 93.3% 75%, hsla(120, 100%, 50%, 0.2), rgba(0,255,0,0) 70.71%)"},
      {duration:0.5, background:"radial-gradient(circle at 50% 0, hsla(0, 100%, 50%, 0.2), rgba(255,0,0,0) 0%), radial-gradient(circle at 6.7% 75%, hsla(240, 100%, 50%, 0.2), rgba(0,0,255,0) 0%), radial-gradient(circle at 93.3% 75%, hsla(120, 100%, 50%, 0.2), rgba(0,255,0,0) 0%)"}
    )  
  })

  $(".containerIndustry").on("pointerenter", function(e){
    gsap.fromTo(
      $(this).find(".industryRainbow"), 
      {duration:0.3, "-webkit-mask-image":"radial-gradient(ellipse at 50% 50%, hsla(0, 0%, 0%, 0), rgba(255, 255, 255, 0.073) 200%, rgba(255, 255, 255, 1) 200%)"},
      {duration:0.3, "-webkit-mask-image":"radial-gradient(ellipse at 50% 50%, hsla(0, 0%, 0%, 0), rgba(255, 255, 255, 0.073) 70%, rgba(255, 255, 255, 1) 130%)"}
    ) 
    /* document.getElementById("cursor-wrapper").classList.add("blob_big_rainbow")  */
  })

  $(".containerIndustry").on("pointerleave", function(e){
    gsap.fromTo(
      $(this).find(".industryRainbow"), 
      {duration:0.3, "-webkit-mask-image":"radial-gradient(ellipse at 50% 50%, hsla(0, 0%, 0%, 0), rgba(255, 255, 255, 0.073) 70%, rgba(255, 255, 255, 1) 130%)"},
      {duration:0.3, "-webkit-mask-image":"radial-gradient(ellipse at 50% 50%, hsla(0, 0%, 0%, 0), rgba(255, 255, 255, 0.073) 200%, rgba(255, 255, 255, 1) 200%)"},
    )  
    /* document.getElementById("cursor-wrapper").classList.remove("blob_big_rainbow") */
  })
}

function inputFile() {
/*   var inputs = document.querySelectorAll(".inputfile");
  Array.prototype.forEach.call(inputs, function (input) {
    var label = input.nextElementSibling,
      labelVal = label.innerHTML;

    input.addEventListener("change", function (e) {
      console.log("inputFile() called here")
      var fileName = "";
      if (this.files && this.files.length > 1)
        fileName = (this.getAttribute("data-multiple-caption") || "").replace(
          "{count}",
          this.files.length
        );
      else fileName = e.target.value.split("\\").pop();

      if (fileName) label.querySelector("span").innerHTML = fileName;
      else label.innerHTML = labelVal;
    });

    // Firefox bug fix
    input.addEventListener("focus", function () {
      input.classList.add("has-focus");
    });
    input.addEventListener("blur", function () {
      input.classList.remove("has-focus");
    });
  }); */
}

export {
  scrollTest,
  siteMenu,
  homeTeamMore,
  homeParallax,
  scholarshipParallax,
  preventReload,
  openPrinciple,
  faqs,
  customSelect,
  videoHover,
  inputFile,
  rainbowFun,
  videoPlay,
  rainbowCover,
  grayCover,
  filterMenu,
  stickFilterMenu
};
