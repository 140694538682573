import Swiper, { FreeMode, Autoplay, Navigation, Pagination, EffectFade, Scrollbar, Mousewheel } from "swiper";
import $ from "jquery";

Swiper.use([Autoplay, Navigation, Pagination, EffectFade, Scrollbar, Mousewheel, FreeMode]);

function featuredSwiper() {
  const homeFeatured = new Swiper(".homeFeatured", {
    loop: true,
    speed: 800,
    allowTouchMove: false,
    autoplay: {
      delay: 5000,
    },
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });
}

function teamSwiper() {
  const homeTeam = new Swiper(".homeTeam", {
    slidesPerView: 1.5,
    direction: "horizontal",
    loop: true,
    freeMode: {
      enabled: true,
      sticky: false,
    },
    scrollbar: {
        el: ".swiper-scrollbar-team",
        draggable: true,
    },
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true
    },
    navigation: {
      nextEl: '.clickGoRight',
      prevEl: '.clickGoLeft',
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 4,
      },
    },
  });
}

function bryanExploreSwiper() {
  const exploreText = new Swiper(".swiper-container", {
    slidesPerView: 1,
    direction: "horizontal",
    loop: true,
    freeMode: {
      enabled: false,
      sticky: true,
    },

    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
    },
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 4,
      },
    },
  });
}

function projectsSwiper() {
  if (window.innerWidth >= 1367) {
    const homeProjects = new Swiper(".homeProjects", {
      slidesPerView: 1,
      loop: true,
      freeMode: {
        enabled: false,
        sticky: true,
      },
  
      scrollbar: {
        el: ".swiper-scrollbar-project",
        draggable: true,
      },
      navigation: {
        nextEl: '.clickGoRight',
        prevEl: '.clickGoLeft',
      },
      mousewheel: {
        releaseOnEdges: true,
        forceToAxis: true
      },
      centeredSlides: true,
      breakpoints: {
        1024: {
          slidesPerView: 2,
        },
      },

      on: {
        touchStart: function () {
          setTimeout(function () {
            $("#homeProjects").addClass("transition");
          }, 200);
        },
        transitionEnd: function () {
          $("#homeProjects").removeClass("transition");

          $(".swiper-slide-prev .contentThumb").css(
            "transform",
            "translate(0,0)"
          );
          $(".swiper-slide-next .contentThumb").css(
            "transform",
            "translate(0,0)"
          );
        },
        touchEnd: function () {
          setTimeout(function () {
            if ($("#homeProjects").hasClass("transition")) {
              $("#homeProjects").removeClass("transition");
            }
          }, 1000);
        },
      },
    });
  } else {
    const homeProjects = new Swiper(".homeProjects", {
      slidesPerView: 1,
      loop: true,
      freeMode: {
        enabled: false,
        sticky: true,
      },  
      centeredSlides: true,
      breakpoints: {
        1024: {
          slidesPerView: 2,
        },
      },
      scrollbar: {
        el: ".swiper-scrollbar-project",
        draggable: true,
      },
      navigation: {
        nextEl: '.clickGoRight',
        prevEl: '.clickGoLeft',
      },
      mousewheel: {
        releaseOnEdges: true,
        forceToAxis: true
      },
      centeredSlides: true,
      breakpoints: {
        1024: {
          slidesPerView: 2,
        },
      },
    });
  }
}

window.onload = window.onresize = projectsSwiper;

function scholarshipSwiper() {
  const scholarshipBlocks = new Swiper(".blocks", {
    slidesPerView: 1,
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
    },
  });
}

function studentsSwiper() {
  const scholarshipStudents = new Swiper(".students", {
    slidesPerView: "auto",
    centeredSlides: true,

    breakpoints: {
      1024: {
        slidesPerView: 4,
        centeredSlides: false,
      },
    },
  });
}

function workSwiper() {
  const jobs = new Swiper(".jobs", {
    slidesPerView: "auto",
    direction: "horizontal",
    //loop: true,
    freeMode: {
      enabled: false,
      sticky: true,
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
    },
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true
    },
    navigation: {
      nextEl: '.clickGoRight',
      prevEl: '.clickGoLeft',
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
      },
    },
  });
}

function pageHeader() {
  const pageHeader = new Swiper(".pageHeader", {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });
}

export {
  featuredSwiper,
  teamSwiper,
  projectsSwiper,
  scholarshipSwiper,
  studentsSwiper,
  workSwiper,
  pageHeader,

  bryanExploreSwiper
};
