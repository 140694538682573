import jQuery from "jquery";
import $ from "jquery";
import { gsap } from "gsap";
import ImagesLoaded from "@utils/imagesLoaded";

class ProjectTransition {
  constructor() {
    this.name = "project-transition";

    this._imagesLoaded = false;
    this._imgLoader = null;
    this._onImagesLoaded = this._onImagesLoaded;
  }

  async beforeLeave({ next }) {
    this._imagesLoaded = false;
    if (!next.container) return;
    this._imgLoader = new ImagesLoaded(next.container, this._onImagesLoaded);
  }

  async beforeEnter(data) {
    if (this._imagesLoaded === true || !this._imagesLoaded) return;

    return new Promise((resolve) => {
      if (!this._imgLoader)
        this._imgLoader = new ImagesLoaded(
          data.next.container,
          this._onImagesLoaded
        );

      this._imgLoader.once("always", resolve);
    });
  }

  async leave(data) {
    this.fadeOut(data.current.container);
    this.fadeIn($(".spinner"))

    //Bryan: save last scroll position
    /* var last_slug = data.current.url.path
    console.log("LEFT " + last_slug)
    if(last_slug.indexOf("the-work") != -1){
      console.log($(window).scrollTop())
      localStorage.setItem("scrollPosition", $(window).scrollTop());
    } */

    await this.delay(1000);
    data.current.container.remove();
  }

  async enter({ next }) {
    this.fadeIn(next.container);
    this.fadeOut($(".spinner"))
    jQuery("body").addClass("loaded");

    //Bryan: load last scroll position
/*     var l = window.location.pathname.split("/")
    var last_slug = l[l.length-2]
    console.log("ENETERED " + last_slug)
    if(last_slug.indexOf("the-work") != -1){
      var pos = localStorage.getItem("scrollPosition");
      window.scrollTo(0, pos);
    } */

    //bryan: why project transition though lol
/*     var is_home = window.location.pathname == "/"
    var is_lab = window.location.pathname == "/lab/"
    var page_slug_list = window.location.pathname.split("/")
    var last_slug = page_slug_list[page_slug_list.length-2]
    var page_name = last_slug.split("-").join(" ").toUpperCase()

    console.log(document.title)

    if (is_home || is_lab){
      $(".logoSite").addClass("logo-home");
      $("#bread-crumb p").html("> " + "HOME")
    }else{
      $(".logoSite").removeClass("logo-home");
      $(".logoWordmark")[0].style.fill = "transparent"
      $("#bread-crumb p").html("> " + page_name)
    } */
  }

  fadeIn = (container) => {
    return gsap.fromTo(
      container,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.5 }
    );
  };

  fadeOut = (container) => {
    return gsap.to(container, { autoAlpha: 0, duration: 0.5 });
  };

  _onImagesLoaded() {
    this._imagesLoaded = true;

    this._imgLoader.destroy();
    this._imgLoader = null;
  }
  delay(n) {
    n = n || 2000;
    return new Promise((resolve) => {
      setTimeout(resolve, n);
      console.log("delay ok");
    });
  }
  initScript() {
    console.log("initScript na overlayTransition");
    console.log("initScript na overlayTransition ending");
  }
}

export default ProjectTransition;
